import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { motion, useAnimation } from "framer-motion";

function Summary() {
	const bodyContainer = {
		hidden: { opacity: 0 },
		show: {
			opacity: 1,
			transition: {
				duration: 0.1,
				// when: "beforeChildren",
				staggerChildren: 1,
			},
		},
	};

	const controls = useAnimation();

	useEffect(() => {
		controls.start("show");
	}, [controls]);

	return (
		<FullContainer>
			<motion.div
				variants={bodyContainer}
				initial="hidden"
				animate={controls}
				css={`
					display: flex;
					flex-direction: column;
					align-items: center;
					gap: 40px;

					font-weight: 300;
					font-size: 18px;
					@media (min-width: 769px) {
						font-size: 22px;
					}

					line-height: 1.55;

					text-align: center;

					color: hsl(8, 45%, 95%);
				`}
			>
				<MotionSpan>
					True tests of bloodless bonds.
					<br />
					In this town, guns, lies, and unsolved enigmas define normality.
				</MotionSpan>
				<MotionSpan>
					The veiled ambiguities suffocate Skylar.
					<br />
					Layers of knowledge hidden from her eyes,
					<br />
					but is this madhouse of history even worth understanding?
				</MotionSpan>
				<MotionSpan>
					Anxiety, shattered memories, and secrets from the past claw their way back.
				</MotionSpan>
				<MotionSpan>If the truth comes out, will Skylar finally be at peace?</MotionSpan>
				<MotionSpan>Or, has the story just begun?</MotionSpan>
			</motion.div>
		</FullContainer>
	);
}

export default Summary;

const MotionSpan = ({ children }) => (
	<motion.span
		variants={{
			hidden: { y: 20, opacity: 0 },
			show: {
				y: 0,
				opacity: 1,
				transition: { duration: 1 },
			},
		}}
	>
		{children}
	</motion.span>
);

const FullContainer = styled.div`
	flex: 1;
	border-top: 2px solid hsl(8, 45%, 90%, 0.2);

	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 100%;

	position: relative;

	gap: 50px;
	@media (min-width: 1025px) {
		gap: 70px;
	}

	padding: 50px 0;
	padding-bottom: 80px;

	border-right: 2px solid hsl(8, 45%, 90%, 0.2);
`;
