import React from "react";
import {} from "styled-components/macro";

function Socials() {
	return (
		<div
			css={`
				display: flex;
				justify-content: space-between;
				align-items: center;

				width: 100%;

				background-color: #1c1817;
			`}
		>
			<div
				css={`
					width: 100%;
					height: 100%;

					display: flex;
					justify-content: space-between;
					align-items: center;

					height: 60px;
					padding: 0 30px;
					@media (max-width: 1024px) {
						height: 50px;
						padding: 0 20px;
					}
					@media (max-width: 450px) {
						height: 40px;
						padding: 0 12px;
					}
				`}
			>
				<a
					href="https://www.instagram.com/anoushkakola/"
					target="_blank"
					rel="noreferrer noopener"
					css={`
						display: flex;
						align-items: center;
						gap: 10px;

						&:hover span {
							color: #f7ae9f;
						}
					`}
				>
					<img
						src="https://kola-productions.nyc3.cdn.digitaloceanspaces.com/IG.svg"
						alt="Instagram Link"
						css={`
							transform: scale(0.9);
							@media (max-width: 425px) {
								transform: scale(0.7);
							}
						`}
						height={25}
						width={25}
					/>
					<span
						css={`
							font-weight: 300;
							font-size: 20px;
							font-size: clamp(1rem, 0.8226rem + 0.6678vw, 1.25rem);
							letter-spacing: 0.075em;

							color: #d6998f;

							@media (max-width: 425px) {
								display: none;
							}
						`}
					>
						@anoushkakola
					</span>
				</a>

				<span
					css={`
						font-weight: 300;
						font-size: 24px;
						font-size: clamp(1rem, 0.6452rem + 1.3356vw, 1.4rem);
						letter-spacing: 0.05em;

						color: #d6998f;

						text-align: end;
					`}
				>
					anoushka kolahalu
				</span>
			</div>
		</div>
	);
}

export default Socials;
