import React from "react";

import {} from "styled-components/macro";
import InfiniteMarquee from "../common/InfiniteMarquee";

function Press() {
	const pressArray = [
		{
			source: "STEREOTYPE CO",
			link: "https://www.stereotypeco.com/blog/anoushka-kolahalu-interview?rq=anoushka%20kolahalu",
		},
		{
			source: "FOX | 40",
			link: "https://www.wicz.com/story/48031220/cozy-crime-mystery-book-to-add-to-the-reading-list-right-now",
		},

		{
			source: "WPGX FOX 28",
			link: "https://www.wpgxfox28.com/story/48031220/cozy-crime-mystery-book-to-add-to-the-reading-list-right-now",
		},
		{
			source: "WTNZ FOX 43",
			link: "https://www.wtnzfox43.com/story/48031220/cozy-crime-mystery-book-to-add-to-the-reading-list-right-now",
		},
		{
			source: "AZ Central",
			link: "http://finance.azcentral.com/azcentral/article/getnews-2022-12-23-cozy-crime-mystery-book-to-add-to-the-reading-list-right-now/",
		},
		{
			source: "Pawtucket Times",
			link: "http://business.pawtuckettimes.com/pawtuckettimes/markets/article/getnews-2022-12-23-cozy-crime-mystery-book-to-add-to-the-reading-list-right-now/",
		},
		{
			source: "Digital Journal",
			link: "https://www.getnews.info/1281922/cozy-crime-mystery-book-to-add-to-the-reading-list-right-now.html",
		},
	];

	return (
		<div
			css={`
				z-index: 1;

				padding: 50px 0 60px 0;
				width: 100%;

				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				row-gap: 20px;

				background-color: #0b0504;

				border-top: 2px solid hsl(8, 45%, 90%, 0.2);
			`}
		>
			<h3
				css={`
					font-size: 20px;
					letter-spacing: 0.4px;
					@media (min-width: 1025px) {
						font-size: 28px;
						letter-spacing: 0.2px;
					}

					font-weight: 300;
					color: rgba(214, 153, 143, 0.7);

					text-transform: uppercase;
				`}
			>
				as seen on
			</h3>

			<InfiniteMarquee
				duration="30s"
				gap="1rem"
				listItems={
					<>
						{pressArray.map((item, index) => (
							<li key={`${item.source}_${index}`}>
								<div
									css={`
										display: flex;
									`}
								>
									<a
										href={item.link}
										target="_blank"
										rel="noopener noreferrer"
										aria-label={`Learn more at ${item.source}`}
										tabIndex={-1}
										css={`
											font-weight: 500;

											font-size: 32px;
											@media (min-width: 1025px) {
												font-size: 48px;
											}

											letter-spacing: 0.5px;
											text-transform: uppercase;

											color: rgba(214, 153, 143, 0.6);
											transition: transform 0.1s ease-in-out;

											&:hover {
												color: #b90000;
												color: #f1aca1;
												transform: scale(1.15);
												transition: transform 0.1s ease-in-out;
											}
										`}
									>
										{item.source}
									</a>
								</div>
							</li>
						))}
					</>
				}
			/>
		</div>
	);
}

export default Press;
