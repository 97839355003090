import React from "react";
import {} from "styled-components/macro";

function InfiniteMarquee({ listItems, gap = "3rem", duration = "10s", hover = false }) {
	return (
		<div
			css={`
				position: relative;

				display: flex;
				gap: ${gap};

				overflow: hidden;
				user-select: none;

				@keyframes scroll {
					from {
						transform: translateX(0);
					}
					to {
						transform: translateX(calc(-100% - ${gap}));
					}
				}

				${hover &&
				`&:hover > ul {
					animation-play-state: paused;
				}`}
			`}
		>
			{Array(2)
				.fill()
				.map((_, index) => (
					<ul
						key={`marquee_list_${index}`}
						css={`
							flex-shrink: 0;
							display: flex;
							justify-content: space-around;
							gap: ${gap};
							min-width: 100%;

							animation: scroll ${duration} linear infinite;

							/* Pause animation when reduced-motion is set */
							@media (prefers-reduced-motion: reduce) {
								animation-play-state: paused !important;
							}
						`}
					>
						{listItems}
					</ul>
				))}
		</div>
	);
}

export default InfiniteMarquee;
