/* eslint-disable global-require */
import React from "react";
import {} from "styled-components/macro";

function Cover() {
	return (
		<div
			css={`
				height: 60vh;
				/* @media (min-width: 450px) {
					height: 70vh;
				} */
				position: fixed;
				top: 0;

				width: 100%;

				background: #0b0005;

				background: linear-gradient(
						180deg,
						rgba(0, 0, 0, 0) 0%,
						rgba(0, 0, 0, 0) 85%,
						#0b0504 100%
					),
					url("https://kola-productions.nyc3.cdn.digitaloceanspaces.com/Notorious%20Five%20%E2%80%94%20Anoushka%20Kolahalu%20%E2%80%94%20silhouettes.webp");
				background-size: cover;

				transition: all 0.1s ease-in-out;

				@media (min-width: 1800px) {
					background-size: 105%;
					background-position: 100% 60%;
				}
				@media (max-width: 1024px) {
					background-position: 60% 60%;
				}
				@media (max-width: 600px) {
					background-position: 55% 60%;
				}

				background-position: 80% 60%;
				background-repeat: no-repeat;
			`}
		/>
	);
}

export default Cover;
