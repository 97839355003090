import axios from "axios";
import React, { useEffect, useState } from "react";
import {} from "styled-components/macro";
// import ThreeDBook from "./ThreeDBook";

function Orders() {
	const [locationData, setLocation] = useState(null);
	// const [locationData, setLocation] = useState({ continent: "North America", countryCode: "US" });

	const imgSrcDictionary = {
		Amazon: "https://kola-productions.nyc3.cdn.digitaloceanspaces.com/Amazon.svg",
		BarnesNoble: "https://kola-productions.nyc3.cdn.digitaloceanspaces.com/BarnesNoble.svg",
		AbeBooks: "https://kola-productions.nyc3.cdn.digitaloceanspaces.com/AbeBooks.svg",
	};

	const amazonLinkDictionary = {
		US: "https://a.co/d/h3d6GCq",
		MX: "https://www.amazon.com.mx/dp/1915852978",
		CA: "https://a.co/d/dZ0vn8P",
		GB: "https://amzn.eu/d/gWMbThO",
		IT: "https://amzn.eu/d/8ZAGuNi",
		NL: "https://amzn.eu/d/1dC867U",
		DE: "https://amzn.eu/d/65xFU93",
		AE: "https://amzn.eu/d/3KQZMV4",
		IN: "https://amzn.eu/d/iFM7ySb",
		PH: "https://a.co/d/h3d6GCq",
	};

	// Primary Used: https://www.abebooks.com/servlet/BookDetailsPL?bi=31396321997
	// Secondary Backup: https://www.abebooks.com/servlet/BookDetailsPL?bi=31396234069
	const abeLinkDictionary = {
		continent: ["EU", "AS"],
		country: ["GB", "IT", "AE", "IN"],
	};

	// Check country / continent location
	useEffect(() => {
		axios
			.get(`https://ipapi.co/json/`)
			.then((res) => {
				// console.log({
				// 	countryCode: res.data?.country_code,
				// 	continent: res.data?.continent_code,
				// });

				setLocation({
					countryCode: res.data?.country_code,
					continent: res.data?.continent_code,
				});
			})
			.catch(() => {
				setLocation({});
			});
	}, []);

	return (
		<div
			css={`
				height: 100%;
				width: 100%;

				flex: 1;

				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				border-top: 2px solid hsl(8, 45%, 90%, 0.2);

				gap: 50px;

				@media (min-width: 1025px) {
					gap: 70px;
				}
			`}
		>
			<h2
				css={`
					color: hsla(8, 46%, 95%, 1);
					text-transform: uppercase;
					font-weight: 500;

					font-size: 18px;
					letter-spacing: 1px;
					@media (min-width: 769px) {
						font-size: 22px;
					}
				`}
			>
				Order Now
			</h2>
			<div
				css={`
					gap: 30px;
					@media (min-width: 1025px) {
						gap: 60px;
					}
					height: 100%;

					display: flex;
					justify-content: center;
					align-items: center;
					flex-direction: column;
				`}
			>
				{locationData ? (
					<>
						<StoreButton
							accessibility="order notorious five on Amazon"
							link={
								amazonLinkDictionary?.[locationData?.countryCode] ||
								amazonLinkDictionary["US"]
							}
							imgComponent={
								<img
									src={imgSrcDictionary["Amazon"]}
									alt="Amazon Store Link"
									loading="eager"
									css={`
										transform: scale(0.875) !important;
									`}
									width={175}
									height={50}
								/>
							}
						/>

						<StoreButton
							accessibility="order notorious five on Barnes & Noble"
							link="https://www.barnesandnoble.com/w/notorious-five-anoushka-kolahalu/1142899696"
							imgComponent={
								<img
									src={imgSrcDictionary["BarnesNoble"]}
									alt="Barnes & Noble Store Link"
									loading="eager"
									css={`
										transform: scale(0.975) !important;
									`}
									width={175}
									height={50}
								/>
							}
						/>

						{(abeLinkDictionary.continent.includes(locationData?.continent) ||
							abeLinkDictionary.country.includes(locationData?.countryCode)) && (
							<StoreButton
								accessibility="order notorious five on AbeBooks"
								link="https://www.abebooks.com/servlet/BookDetailsPL?bi=31396321997"
								imgComponent={
									<img
										src={imgSrcDictionary["AbeBooks"]}
										alt="AbeBooks Store Link"
										loading="eager"
										css={`
											transform: scale(1.2) !important;
										`}
										width={175}
										height={50}
									/>
								}
							/>
						)}
					</>
				) : (
					<div
						css={`
							&::after {
								content: " ";
								display: block;
								width: 32px;
								height: 32px;
								margin: 8px;
								border-radius: 50%;
								border: 4px solid #d6998f;
								border-color: #d6998f transparent #d6998f transparent;
								animation: loader 1.2s linear infinite;
							}

							@keyframes loader {
								0% {
									transform: rotate(0deg);
								}
								100% {
									transform: rotate(360deg);
								}
							}
						`}
					></div>
				)}

				{/* <div
					css={`
						margin-top: 40px;
						@media (max-width: 1024px) {
							display: none;
						}
					`}
				>
					<ThreeDBook />
				</div> */}
			</div>
		</div>
	);
}

const StoreButton = ({ accessibility, bg, link, imgComponent }) => (
	<a
		href={link}
		target="_blank"
		rel="noopener noreferrer"
		aria-label={accessibility}
		css={`
			cursor: pointer;

			display: flex;
			justify-content: center;
			align-items: center;
			height: 60px;
			padding: 10px 0;
			min-width: 200px;

			transition: opacity 0.2s ease-in-out, scale 0.1s ease-in-out;

			opacity: 1;
			&:hover {
				scale: 1.1;
			}

			@media (max-width: 1024px) {
				scale: 1.2;
				&:hover {
					scale: 1.4;
				}
			}

			& > img {
				user-select: none;

				width: 280px;
				@media (max-width: 1024px) {
					width: 200px;
				}
				@media (max-width: 425px) {
					width: 175px;
				}
			}
		`}
	>
		{imgComponent}
	</a>
);

export default Orders;
