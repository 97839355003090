import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    body {
        font-family: azo-sans-web, sans-serif;
        background: #0b0504;
    }

    *::selection {
        background: ${({ theme }) => theme.color.secondary}; /* WebKit/Blink Browsers */
        color: #ddd;
    }
    *::-moz-selection {
        background: ${({ theme }) => theme.color.secondary}; /* Gecko Browsers */
        color: #ddd;
    }

    /* width */
    ::-webkit-scrollbar {
        width: 8px;

        background: red;

        @media (max-width: 1024px) {
            width: 6px;
        }
        
        @media (max-width: 425px) {
            display:none;
        }
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #1c1817;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb , ::-webkit-scrollbar-thumb:hover{
        background: hsla(8, 46%, 70%, 1);
        border-radius: 100px;
    }


`;

export default GlobalStyle;
