const text100 = "#FEF1E6ee";

const primary = "#9e0000";
const secondary = "#b90000";

const theme = {
	color: {
		background: primary,

		primary,
		secondary,
	},

	font: {
		color: {
			text100,
		},
	},
};

export default theme;
