import React from "react";

import Summary from "./components/Home/Summary";
import Press from "./components/Home/Press";
import Cover from "./components/Home/Cover";
import Orders from "./components/Home/Orders";
import {} from "styled-components/macro";
import { SuperSEO } from "react-super-seo";
import Socials from "./components/Home/Socials";

function App() {
	return (
		<div
			css={`
				position: relative;

				min-height: 100vh;
				width: 100%;

				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				overflow-x: hidden;
			`}
		>
			<link
				rel="preload"
				href="https://kola-productions.nyc3.cdn.digitaloceanspaces.com/Notorious%20Five%20%E2%80%94%20Anoushka%20Kolahalu%20%E2%80%94%20silhouettes.webp"
				as="image"
			/>

			<SuperSEO
				title="Notorious Five by Anoushka Kolahalu"
				description="A game of unsolved enigmas and wild ambiguities tests a teenager's ability to handle it all. Can Skylar balance her normal life and the secrets of her past?"
				lang="en"
				canonical="https://kola2.com/"
				openGraph={{
					ogUrl: "https://kola2.com/",
					ogTitle: "Notorious Five by Anoushka Kolahalu",
					ogType: "website",
					ogDescription:
						"A game of unsolved enigmas and wild ambiguities tests a teenager's ability to handle it all. Can Skylar balance her normal life and the secrets of her past?",
					ogImage: [
						{
							ogImage:
								"https://kola-productions.nyc3.cdn.digitaloceanspaces.com/notorious_five.jpeg",
							ogImageWidth: 800,
							ogImageHeight: 600,
							ogImageAlt: "Notorious Five book",
							ogImageType: "image/jpeg",
						},
					],
					ogSiteName: "Notorious Five by Anoushka Kolahalu",
				}}
				twitter={{
					twitterSummaryCard: {
						summaryCardTitle: "Notorious Five by Anoushka Kolahalu",
						summaryCardDescription:
							"A game of unsolved enigmas and wild ambiguities tests a teenager's ability to handle it all. Can Skylar balance her normal life and the secrets of her past?",
						summaryCardImage:
							"https://kola-productions.nyc3.cdn.digitaloceanspaces.com/notorious_five.jpeg",
						summaryCardImageAlt: "Notorious Five Book",
					},
				}}
			>
				<link rel="canonical" href="https://kola2.com/" />
				<meta
					name="keywords"
					content="Notorious Five, Anoushka Kolahalu, crime, mystery, book"
				/>
			</SuperSEO>

			<Cover />

			<div
				css={`
					margin-top: 60vh;
					padding-top: 80px;

					width: 100%;

					z-index: 1;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;

					background-color: #0b0504;

					// gradient fade top
					background: linear-gradient(to top, #0b0504 98%, rgba(11, 5, 4, 0));
				`}
			>
				<div
					css={`
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;

						padding-bottom: 40px;

						@media (min-width: 769px) {
							padding-bottom: 60px;
						}
					`}
				>
					<h1
						css={`
							color: hsla(8, 46%, 90%, 1);

							font-weight: 500;
							letter-spacing: -0.4px;
							font-style: normal;
							font-size: 96px;
							font-size: clamp(2.75rem, 0.4441rem + 8.6811vw, 6rem);

							line-height: 1.2;
						`}
					>
						notorious five
					</h1>
					<h2
						css={`
							z-index: 1;
							color: hsla(8, 46%, 80%, 01);
							font-style: italic;
							font-weight: 300;
							letter-spacing: 0.6px;
							font-size: 24px;
							font-size: clamp(1.125rem, 0.8589rem + 1.0017vw, 1.5rem);

							line-height: 1.2;
						`}
					>
						written by anoushka kolahalu
					</h2>
				</div>

				<div
					css={`
						display: flex;
						justify-content: space-evenly;
						align-items: flex-start;
						width: 100%;

						flex-direction: column;
						@media (min-width: 1025px) {
							flex-direction: row;
						}

						background-color: hsl(5 70% 25% / 1);
						background-color: #68160f;

						& > *:first-child {
							padding: 50px 20px;
							@media (min-width: 1025px) {
								padding: 200px 20px;
							}
						}
						& > *:last-child {
							padding: 50px 0 70px 0;
							@media (min-width: 1025px) {
								padding: 200px 20px;
							}
						}

						// top inner shadow and bottom inner shadow
						box-shadow: inset 0px 20px 20px -20px rgba(0, 0, 0, 0.75),
							inset 0px -20px 20px -20px rgba(0, 0, 0, 0.75);
					`}
				>
					<Summary />
					<Orders />
				</div>
			</div>

			<Press />
			<Socials />
		</div>
	);
}

export default App;
